<template>
  <div class="plans py-5 container">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="font-weight-bold">Conoce nuestros planes</h2>
      </div>
      <div class="col-12 text-center">
        <p class="lead">Elige el Plan que mejor se adapte a tus necesidades</p>
      </div>
      <div class="col-12">
        <Carousel
          v-bind:autoplay="true"
          v-bind:perPageCustom="[
            [0, 1],
            [720, 2],
            [1024, 3],
          ]"
          v-bind:scrollPerPage="true"
        >
          <Slide
            class="col-12 mt-4 pt-2 justify-text"
            v-for="plan in plans"
            v-bind:key="plan.id"
          >
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-white
                border-0
                rounded
              "
            >
              <div
                class="ribbon ribbon-right ribbon-warning overflow-hidden"
                v-if="plan.featured"
              >
                <span class="text-center d-block shadow small h6">Best</span>
              </div>
              <div class="card-body rounded">
                <h6
                  class="
                    text-center
                    title
                    font-weight-bold
                    text-uppercase text-primary
                    mb-4
                  "
                >
                  {{ plan.name }}
                </h6>
                <div class="mb-4 text-center">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">{{ plan.price }}</span>
                  <span class="h4 align-self-end mb-1"
                    >/{{ getBillingFrequencyAbbr(plan.billingFrequency) }}</span
                  >
                </div>
                <div
                  class="row"
                  v-for="(item, idx) in plan.description"
                  v-bind:key="idx"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="green"
                    class="bi bi-check-circle col-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                    />
                  </svg>
                  <p class="col-10">
                    {{ item }}
                  </p>
                </div>
                <ul class="list-unstyled mb-0 pl-0">
                  <li
                    v-for="(feature, idx) in plan.features"
                    v-bind:key="idx"
                    class="h6 text-muted mb-0"
                  >
                    <span class="text-primary h5 mr-2">
                      <i class="uil uil-check-circle align-middle"></i>
                    </span>
                    {{ feature.description }}
                  </li>
                </ul>
                <router-link
                  class="btn btn-primary mt-4 text-center"
                  v-bind:to="{ name: 'Checkout', params: { id: plan.id } }"
                >
                  Solicitar
                </router-link>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters({
      plans: "plans/ordered",
    }),
  },
  methods: {
    getBillingFrequencyAbbr(frequency) {
      switch (frequency) {
        case "daily":
          return "day";
        case "weekly":
          return "week";
        case "monthly":
          return "month";
        case "yearly":
          return "year";
      }
    },
  },
};
</script>

<style lang="scss">
.VueCarousel-wrapper {
  padding-bottom: 1rem;
}
</style>