var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plans py-5 container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-12"},[_c('Carousel',{attrs:{"autoplay":true,"perPageCustom":[
          [0, 1],
          [720, 2],
          [1024, 3],
        ],"scrollPerPage":true}},_vm._l((_vm.plans),function(plan){return _c('Slide',{key:plan.id,staticClass:"col-12 mt-4 pt-2 justify-text"},[_c('div',{staticClass:"card pricing-rates business-rate shadow bg-white border-0 rounded"},[(plan.featured)?_c('div',{staticClass:"ribbon ribbon-right ribbon-warning overflow-hidden"},[_c('span',{staticClass:"text-center d-block shadow small h6"},[_vm._v("Best")])]):_vm._e(),_c('div',{staticClass:"card-body rounded"},[_c('h6',{staticClass:"text-center title font-weight-bold text-uppercase text-primary mb-4"},[_vm._v(" "+_vm._s(plan.name)+" ")]),_c('div',{staticClass:"mb-4 text-center"},[_c('span',{staticClass:"h4 mb-0 mt-2"},[_vm._v("$")]),_c('span',{staticClass:"price h1 mb-0"},[_vm._v(_vm._s(plan.price))]),_c('span',{staticClass:"h4 align-self-end mb-1"},[_vm._v("/"+_vm._s(_vm.getBillingFrequencyAbbr(plan.billingFrequency)))])]),_vm._l((plan.description),function(item,idx){return _c('div',{key:idx,staticClass:"row"},[_c('svg',{staticClass:"bi bi-check-circle col-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"green","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"}})]),_c('p',{staticClass:"col-10"},[_vm._v(" "+_vm._s(item)+" ")])])}),_c('ul',{staticClass:"list-unstyled mb-0 pl-0"},_vm._l((plan.features),function(feature,idx){return _c('li',{key:idx,staticClass:"h6 text-muted mb-0"},[_c('span',{staticClass:"text-primary h5 mr-2"},[_c('i',{staticClass:"uil uil-check-circle align-middle"})]),_vm._v(" "+_vm._s(feature.description)+" ")])}),0),_c('router-link',{staticClass:"btn btn-primary mt-4 text-center",attrs:{"to":{ name: 'Checkout', params: { id: plan.id } }}},[_vm._v(" Solicitar ")])],2)])])}),1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center"},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Conoce nuestros planes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"lead"},[_vm._v("Elige el Plan que mejor se adapte a tus necesidades")])])
}]

export { render, staticRenderFns }